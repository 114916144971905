// roleperm

<template>
	<v-card dark tile flat>
		<v-toolbar
			color="indigo lighten-1"
			dark
			flat tile
		>
			<v-app-bar-nav-icon></v-app-bar-nav-icon>

			<v-toolbar-title>RolePermissions</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn icon>
				<v-icon>mdi-magnify</v-icon>
			</v-btn>

			<v-btn icon>
				<v-icon>mdi-dots-vertical</v-icon>
			</v-btn>

			<template v-slot:extension>
				<v-tabs
					v-model="tab"
					align-with-title
					color="indigo darken-4"
					background-color="transparent"
					slider-color="indigo darken-4"
					dark
					next-icon="mdi-arrow-right-bold-box-outline"
					prev-icon="mdi-arrow-left-bold-box-outline"
					show-arrows show-arrows-on-hover
				>
					<v-tab
						v-for="i in 30"
						:key="i"
					>
						Table {{ i }}
					</v-tab>
				</v-tabs>
			</template>
		</v-toolbar>
		<v-tabs-items v-model="tab">
			<v-tab-item
			v-for="i in 30"
			:key="i"
			>
				<v-card flat tile dark color="grey darken-2">
					<v-container class="grey darken-3">
						<v-row v-for="i2 in 6" :key="i2">
							<v-col cols="12" sm="2">
								<v-text-field readonly solo filled hide-details :value="'Role '+i2">
								</v-text-field>
                        	</v-col>
							<v-col cols="12" sm="10">
								<v-chip-group v-model="selection" multiple column
									:mandatory="false" active-class="primary--text">
									<v-chip v-for="(item,idx) in items" :key="idx" :value="idx" @click="onToggleRole()">
										<v-avatar v-if="isActive(idx)"><v-icon>check_circle_outline</v-icon></v-avatar>
										{{ item }}
									</v-chip>
								</v-chip-group>
							</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-tab-item>
		</v-tabs-items>
	</v-card>
</template>

<script>
	//import X from './x'
	//import { mapGetters } from 'vuex'
	export default {
	  	name: 'roleperm',
		components: {},
	  	props: {
	  		item: {
	  			type: Object,
	  			default () {
	  				return {}
	  			}
	  		},
	  	},
		data() {
			return {
                tab: null,
                tab2: null,
                items: [
                    'web', 'shopping', 'videos', 'images', 'news',
                ],
                selection: [],
            }
        },
		computed: {},
		methods: {
            onToggleRole() {
            
            },
            isActive(i) {
            
            },
        },
		mounted() {},
	  	watch: {},
    }
</script>

<style>
</style>